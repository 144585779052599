import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import useAuthToken from 'hooks/useAuthToken';

import CircularLoader from 'components/circular-loader';

import paths from './paths';

import { AppState } from 'interfaces/app-state-interface';

const Routes = () => {
  useAuthToken();
  const isTokenValid = useSelector<AppState, boolean>((state) => state.auth.isTokenValid);

  const loginUrl =
    'https://authclear.cloudremedy.io/login?response_type=code&client_id=2ncq2ko7o2nk3q953latrsn6gt&redirect_uri=https://' + window.location.hostname + '/';

  return (
    <div>
      <Switch>
        {isTokenValid &&
          Object.values(paths).map((route, index) => (
            <Route exact path={route.path} component={route.component} key={`route-${index}`} />
          ))}

        {!isTokenValid && (
          <Route
            render={() => {
              window.location.href = loginUrl;

              return null;
            }}
          />
        )}
      </Switch>
      {!isTokenValid && <CircularLoader />}
    </div>
  );
};

export default Routes;
